import { differenceInDays, eachDayOfInterval, format } from "date-fns";

const combineUpcomingLeavesAndHolidays = (leaves = [], holidays = []) => {
  const combinedArray = [];

  // Map holidays to the desired format
  const holidaysMapped = holidays?.map((holiday) => ({
    startDate: new Date(holiday?.StartDate),
    isHoliday: true,
    endDate: holiday.EndDate ? new Date(holiday?.EndDate) : null,
    label: holiday?.Name,
    totalTimeOff:
      differenceInDays(
        new Date(holiday?.EndDate ? holiday?.EndDate : holiday?.StartDate),
        new Date(holiday?.StartDate)
      ) + 1 || "",
  }));

  // Map leaves to the desired format
  const leavesMapped = leaves?.map((leave) => ({
    startDate: new Date(leave?.dateFrom),
    endDate: new Date(leave?.dateTo),
    label: leave?.leaveType,
    isLeave: true,
    totalTimeOff: leave?.noOfLeaves,
  }));

  // Concatenate the mapped arrays
  combinedArray.push(...holidaysMapped, ...leavesMapped);

  // Sort the combined array by startDate
  combinedArray.sort((a, b) => a?.startDate - b?.startDate);

  return combinedArray;
};

export default combineUpcomingLeavesAndHolidays;

export function getDatesBetweenDateRange(startDate, endDate) {
  if (startDate && endDate) {
    const dates = eachDayOfInterval({ start: new Date(startDate), end: new Date(endDate) });
    return dates.map((date) => ({ leaveDate: format(date, "yyyy-MM-dd") }));
  }
  return null;
}
